// TODO: Rewrite - Generated by CoffeeScript 2.4.1
;(function () {
  $(document).ready(function () {
    var $location,
      $locationInput,
      $mapbox,
      $heroSearchMapbox,
      countryCode,
      currentSearch,
      firstSuggestionOnEnter,
      geocoder,
      setCurrentSearch,
      heroSearchGeocoder,
      $heroSearchInput,
      $heroSearchLongitude,
      $heroSearchLatitude,
      defaultHeroSearchLocations

    countryCode = window.cndVars.countryCode
    currentSearch = {}
    $mapbox = $('#mapbox-container')
    $heroSearchMapbox = $('#mapbox-hero-search')
    defaultHeroSearchLocations = {
      au: 'Sydney NSW 2000, Australia',
      us: 'Boston, MA, USA',
      ca: 'Toronto, ON, Canada',
    }

    geocoder = new MapboxGeocoder({
      accessToken: process.env.MAPBOX_ACCESS_TOKEN,
      types: 'locality,postcode,address,place,poi',
      placeholder: 'City, hotel or address',
      countries: countryCode,
    })
    $mapbox.append(geocoder.onAdd())
    $locationInput = $mapbox.find('.mapboxgl-ctrl-geocoder--input')

    // Update location in header navigation search box
    const navSearch = document.querySelector('.mapboxgl-ctrl-geocoder--input')
    if (navSearch) {
      const location = document.getElementById('location')
      location?.value && navSearch.setAttribute('value', location.value)
    }

    $locationInput.attr('name', 'location')
    $locationInput.attr('autocomplete', 'off')
    $locationInput.addClass('cnd-input')
    $locationInput.addClass('cyp-search-autocomplete')
    $locationInput.addClass('cnd-autocomplete')
    $locationInput.removeClass('mapboxgl-ctrl-geocoder--input')

    heroSearchGeocoder = new MapboxGeocoder({
      accessToken: process.env.MAPBOX_ACCESS_TOKEN,
      types: 'locality,postcode,address,place,poi',
      placeholder: 'City, hotel or address',
      countries: countryCode,
    })
    $heroSearchMapbox.append(heroSearchGeocoder.onAdd())
    $heroSearchInput = $heroSearchMapbox.find('.mapboxgl-ctrl-geocoder--input')
    $heroSearchInput.val(defaultHeroSearchLocations[countryCode])
    $heroSearchInput.attr('data-location-input', '')
    $heroSearchInput.attr('name', 'location')
    $heroSearchInput.attr('autocomplete', 'off')
    $heroSearchInput.addClass('cnd-input')
    $heroSearchInput.addClass('cyp-search-autocomplete')
    $heroSearchInput.addClass('cnd-autocomplete')
    $heroSearchInput.removeClass('mapboxgl-ctrl-geocoder--input')

    $heroSearchLongitude = $('#mapbox-hero-search-longitude')
    $heroSearchLatitude = $('#mapbox-hero-search-latitude')

    heroSearchGeocoder.on('result', function (results) {
      const place = results.result

      $heroSearchLongitude.val(place.center[0])
      $heroSearchLatitude.val(place.center[1])
    })

    setCurrentSearch = function (obj = currentSearch) {
      const searchParams = new URLSearchParams(window.location.search)
      $.extend(currentSearch, obj)

      searchParams.set('location', currentSearch.location)
      searchParams.set('latitude', currentSearch.latitude)
      searchParams.set('longitude', currentSearch.longitude)
      $locationInput.val(currentSearch.location).change()
      return (window.location = `/${window.cndVars.countryCode.toLowerCase()}/en/search?${searchParams.toString()}`)
    }
    if (!$locationInput[0]) {
      return
    }

    var $clearButton = $('.js-nav__search__clear-button')
    var toggleClearButton = (locationValue) => {
      if (locationValue || $locationInput.val()) {
        $clearButton.removeClass('cnd-hidden')
      } else {
        $clearButton.addClass('cnd-hidden')
      }
    }

    $clearButton.click(() => {
      $locationInput.val('').focus()
      toggleClearButton()
    })
    $locationInput.keyup(() => toggleClearButton())

    geocoder.on('result', function (results) {
      var place
      place = results.result
      return setCurrentSearch({
        location: place.place_name,
        latitude: place.center[1],
        longitude: place.center[0],
      })
    })
    // If no suggestion is selected, enter will select the first suggestion
    firstSuggestionOnEnter = function (input) {
      var _addEventListener, addEventListenerWrapper
      // Store the original event binding function
      _addEventListener = input.addEventListener ? input.addEventListener : input.attachEvent
      addEventListenerWrapper = function (type, listener) {
        var $pacContainer, _listener
        $pacContainer = $('.pac-container')
        // Simulate a 'down arrow' and then trigger the original listener
        if (type === 'keydown') {
          _listener = listener
          $pacContainer.addClass('pac-container__autoselect')
          listener = function (event) {
            var $pacSelectedItem, selectedSuggestion, simulatedDownArrow
            $pacContainer = $('.pac-container')
            $pacSelectedItem = $('.pac-item-selected')
            selectedSuggestion = $pacSelectedItem.length > 0
            if (event.which === 13 && !selectedSuggestion) {
              simulatedDownArrow = $.Event('keydown', {
                keyCode: 40,
                which: 40,
              })
              _listener.apply(input, [simulatedDownArrow])
            } else if (selectedSuggestion) {
              if ($pacSelectedItem.is(':first-child') && event.which === 38) {
                $pacSelectedItem.removeClass('deselect')
              } else {
                $pacContainer.find('.pac-item').first().addClass('deselect')
              }
            } else if (!selectedSuggestion) {
              $pacContainer.find('.pac-item').first().removeClass('deselect')
            }
            return _listener.apply(input, [event])
          }
        }
        return _addEventListener.apply(input, [type, listener])
      }
      input.addEventListener = addEventListenerWrapper
      return (input.attachEvent = addEventListenerWrapper)
    }
    firstSuggestionOnEnter($locationInput[0])
    $location = $('#location')
    if ($location.length) {
      toggleClearButton($location.val())

      return firstSuggestionOnEnter($location[0])
    }
  })
}).call(this)
